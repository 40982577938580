<template>
  <section class="py-20 px-6 text-white" id="service">
    <div class="container mx-auto">
      <h3 class="text-center text-4xl font-bold">What's Included</h3>
      <div class="mt-20 bg-white text-black p-10 rounded-xl leading-loose">
        <div class="flex flex-col items-center">
          <img :src="require('@/assets/images/happy_news.svg')" width="100px" />
          <h4 class="text-2xl font-semibold underline mt-5">Action Plan Content & Automations</h4>
        </div>
         <p class="mt-5">This content includes reply-based emails{{ crm === 'Sierra' ? ' and texts' : '' }}, educational content, and incorporates your website content including the about page, sell pages, buy pages, blogs, and reviews online. A portion of the scripting for the emails is courtesy of the book <a href="https://www.ewts4re.com/" target="_blank" class="text-site-purple font-semibold underline">Exactly What To Say for Real Estate Agents by Phil M. Jones, Chris Smith, and Jimmy Mackin.</a> The initial text sent for new buyer leads &amp; new seller leads is customized by the source type &amp; the specific address inquiry if there is one in order to personalize it as much as possible.</p>
         <a class="inline-block text-site-purple font-semibold mt-5 cursor-pointer select-none" @click="showMoreActionPlans = !showMoreActionPlans">Read {{ showMoreActionPlans ? 'Less' : 'More' }} <font-awesome-icon :icon="`fa-solid fa-chevron-${showMoreActionPlans ? 'up' : 'down'}`" /></a>
         <div v-if="showMoreActionPlans" class="mt-5">
          <ul v-if="fub" class="list-disc">
            <li><strong>New Buyer</strong> - 2 years of emails (approx. 29 emails)</li>
            <li><strong>New Zillow, Realtor, Trulia, Redfin</strong> - 2 years of emails (28 emails)</li>
            <li><strong>New Open House Lead</strong> - 2 years of emails (28 emails)</li>
            <li><strong>New Seller</strong> - 1 year of emails (approx. 17 emails)</li>
            <li><strong>Nurture Buyer</strong> - 3 years of emails (approx. 40 emails)</li>
            <li><strong>Nurture Seller</strong> - 3 years of emails (approx. 38 emails)</li>
            <li><strong>Post Closing</strong> - 11 years of emails (approx. 50 emails)</li>
            <li><strong>Back To Website</strong> - 6 months of emails (9 emails)</li>
            <li><strong>Stale</strong> - 12 months of emails (15 emails)</li>
            <li><strong>Sphere Nurture</strong> - 4 years of emails (approx. 21 emails)</li>
            <li><strong>Renter To Buyer Drip</strong> - 2.5 years of emails (27 emails) partial content courtesy of <a href="https://www.tooniceforsales.com/" class="text-site-purple font-semibold underline cursor-pointer" target="_blank">Barry Jenkins</a></li>
            <li><strong>New Rental Lead</strong> - 12 months of emails (18 emails)</li>
            <li><strong>Branded Website Action Plan</strong> - 6 months of emails (12 emails)</li>
            <li><strong>Home Anniversary Action Plan</strong> - Spans to year 10</li>
            <li><strong>Pausing Action Plans Based On Stage Change</strong></li>
            <li><strong>Birthday</strong> - 20 years (1 email a year - Not yet available in Spanish)</li>
          </ul>
          <ul v-else class="list-disc">
            <li><strong>New Buyer</strong> - 2 years long (approx. 32 emails & 20 texts)</li>
            <li><strong>New Zillow, Realtor, Trulia, Redfin</strong> - 2 years long (approx. 28 emails & 30 texts)</li>
            <li><strong>New Open House Lead</strong> - 2 years long (approx. 31 emails & 20 texts)</li>
            <li><strong>New Seller</strong> - 1 year long (approx. 22 emails & 18 texts)</li>
            <li><strong>Nurture Buyer</strong> - 3 years long (approx. 40 emails & 23 texts)</li>
            <li><strong>Nurture Seller</strong> - 3 years long (approx. 38 emails & 23 texts)</li>
            <li><strong>Post Closing</strong> - 11 years long (approx. 50 emails)</li>
            <li><strong>Back To Website</strong> - 6 months long (9 emails & 9 texts)</li>
            <li><strong>Stale</strong> - 1 year long (approx. 15 emails & 15 texts)</li>
            <li><strong>Sphere Nurture</strong> - 5 years long (approx. 21 emails)</li>
            <li><strong>Renter To Buyer Drip</strong> - 2.5 years long (approx. 21 emails & 14 texts) partial content courtesy of <a href="https://www.tooniceforsales.com/" class="text-site-purple font-semibold underline cursor-pointer" target="_blank">Barry Jenkins</a></li>
            <li><strong>New Rental Lead</strong> - 1 year long (approx. 19 emails & 19 texts)</li>
            <li><strong>Branded Website Action Plan</strong> - 8 months long (approx. 15 emails & 11 texts)</li>
            <li><strong>Birthday Automated Email</strong> - Sent annually on the lead/client's birthday</li>
            <li><strong>Home Anniversary Automated Email</strong> - Sent annually on lead/client's home anniversary</li>

          </ul>
          <div class="bg-gray-200 p-10 mt-10 rounded-lg">
            <RevampExtended :crm="crm" :price="revampExtendedPricing" />
          </div>
          <div class="bg-gray-200 p-10 mt-10 rounded-lg">
            <div class="text-center">
              <h1 class="text-3xl font-semibold">🎉 Platform Pairing 🎉</h1>
              <h2 class="text-xl">Optional Add-ons - $100 per platform</h2>
            </div>
            <ul class="list-disc mt-5">
              <li><strong>Ylopo Users</strong> - Auto-Pausing AI Texting With Accountability</li>
              <li><strong>Conversion Monster Users</strong> - Moving Leads To Stages Based On CM Tags + Applying Nurture Campaigns</li>
              <li v-if="fub"><strong>RealScout Users</strong> - 6 automations based on behavior tags</li>
              <li><strong>Fello Users</strong> - 9 automations based on behavior tags</li>
            </ul>
          </div>
        </div>
        <hr>
        <div v-if="fub">
          <div class="flex flex-col items-center">
          <img :src="require('@/assets/images/deals.svg')" width="120px" />
          <h4 class="text-2xl font-semibold underline mt-5">Deals Automations</h4>
          </div>
          <p class="mt-5">Automate your follow-up after a buyer or a seller becomes a signed client. These are email templates you will want to customize to ensure they are compliant with your brokerage and local regulations.</p>
          <a class="inline-block text-site-purple font-semibold mt-5 cursor-pointer select-none" @click="showMoreDealsAutomations = !showMoreDealsAutomations">Read {{ showMoreDealsAutomations ? 'Less' : 'More' }} <font-awesome-icon :icon="`fa-solid fa-chevron-${showMoreDealsAutomations ? 'up' : 'down'}`" /></a>
          <p v-if="showMoreDealsAutomations" class="mt-5">
            <ul class="list-disc">
              <li><strong>Active Buyer</strong> - Actively looking for a home - tips to stay motivated, encouraged, and stay sane.</li>
              <li><strong>Pending Buyer</strong> - Offer is accepted - what to expect next.</li>
              <li><strong>Pre-Listed Seller</strong> - Not active on market yet - how to prepare & what we will do for you.</li>
              <li><strong>Listed Seller</strong> - What to expect now that you're live on the market.</li>
              <li><strong>Pending Seller</strong> - What to expect now they've accepted an offer.</li>
            </ul>
          </p>
          <hr>
        </div>
        <div class="flex flex-col items-center">
          <img :src="require('@/assets/images/banking.svg')" width="120px" />
          <h4 class="text-2xl font-semibold underline mt-5">Lender Action Plans (optional add-on)</h4>
        </div>
        <p class="mt-5"><strong>PLEASE NOTE: We do not provide Spanish translations for our lender action plans at this time.</strong></p>
        <ul v-if="fub" class="list-disc mt-5">
          <li><strong>new internet lead</strong> - 18 months of emails (approx. 24 emails)</li>
          <li><strong>new agent referral lead</strong> - 18 months of emails (approx. 24 emails)</li>
          <li><strong>nurture lead</strong> - 3 years of emails (approx. 40 emails)</li>
          <li><strong>credit repair nurture</strong> - 20 months of emails (approx. 22 emails)</li>
          <li><strong>stale lead</strong> - 1 year of emails (approx. 15 emails)</li>
          <li><strong>home anniversary</strong> - 10 years of emails (approx. 10 emails)</li>
          <li><strong>post closing</strong> - 5 years of emails (approx. 26 emails)</li>
        </ul>
        <ul v-else class="list-disc mt-5">
          <li><strong>New Internet Lead</strong> - 1.5 years long (approx. 24 emails & 20 texts)</li>
          <li><strong>New Agent Referral Lead</strong> - 1.5 years long (approx. 24 emails & 20 texts)</li>
          <li><strong>Nurture Lead</strong> - 3 years long (approx. 40 emails & 18 texts)</li>
          <li><strong>Credit Repair Nurture</strong> - 3 years long  (approx. 22 emails & 16 texts)</li>
          <li><strong>Stale Lead</strong> - 1 year long (approx. 15 emails & 15 texts)</li>
          <li><strong>Post Closing</strong> - 5 years of emails (approx. 26 emails)</li>
          <li><strong>Birthday Automated Email</strong> - Sent annually on the lead/clien's birthday</li>
          <li><strong>Home Anniversary Automated Email</strong> - Sent annually on lead/client's home anniversary</li>
        </ul>
        <p v-if="fub" class="mt-5">If you are a lender looking for our lender specific revamp <router-link class="text-site-purple font-semibold underline cursor-pointer" to="/lender">click here</router-link> to learn more.</p>
        <hr>
        <div class="flex flex-col items-center">
          <img :src="require('@/assets/images/cleanup.svg')" width="120px" />
          <h4 class="text-2xl font-semibold underline mt-5">Stages, Definitions, and Lead {{ fub ? 'Flows' : 'Routing' }}</h4>
        </div>
         <p class="mt-5">
          <ol v-if="fub" class="list-decimal">
            <li>Provide new, simplified stages, define your new stages in your FUB account to help simplify your processes, collaborate with client to merge previous stages.</li>
            <li class="mt-5">Set up lead flows to ensure all new lead sources have the correct action plan assigned.</li>
          </ol>
          <ol v-else class="list-decimal">
            <li>Redefine your Sierra statuses to help simplify your processes.</li>
            <li class="mt-5">Set up lead routing to ensure all new lead sources have the correct action plan assigned.</li>
          </ol>
        </p>
        <hr>
        <div v-if="fub">
          <div class="flex flex-col items-center">
            <img :src="require('@/assets/images/lists.svg')" width="120px" />
            <h4 class="text-2xl font-semibold underline mt-5">Smart Lists</h4>
          </div>
          <p class="mt-5">There are a total of ten smart lists arranged by importance. These smart lists update automatically based on the filters set up. This will help you spend less time guessing who to call, pushing tasks forward, and focus on converting.</p>
          <p class="mt-5">If you are a Ylopo client or a Call Action client, additional smart lists will be added to ensure you don't miss out on important opportunities.</p>
        </div>
        <div v-else>
          <div class="flex flex-col items-center">
            <img :src="require('@/assets/images/lists.svg')" width="120px" />
            <h4 class="text-2xl font-semibold underline mt-5">Smart Filters</h4>
          </div>
          <p class="mt-5">There are a total of 12 smart filters arranged by importance. These smart filters update automatically based on the saved smart filters set up. This will help you spend less time guessing who to call, pushing tasks forward, and focus on converting.</p>
          <p class="mt-5">If you are a Ylopo client, additional smart filters will be added to ensure you don't miss out on important opportunities.</p>
        </div>
        <hr>
        <div v-if="fub">
          <div class="flex flex-col items-center">
          <img :src="require('@/assets/images/code.svg')" width="120px" />
          <h4 class="text-2xl font-semibold underline mt-5">Courtesy install of the Follow Up Boss Pixel</h4>
          </div>
          <p class="mt-5">As a courtesy to those who sign up for our FUB revamp service, we can attempt the installation of the Follow Up Boss Pixel on your website. With the Follow Up Boss Pixel installed on your website you can track lead activity and much more. With regards to our setup, the Follow Up Boss Pixel is the basis for smart lists and automations that pertain to website activity.</p>
          <p class="mt-5 text-sm font-medium">Please note that not every website is compatible with the Follow Up Boss Pixel therefore we cannot guarantee the success of the install.</p>
          <p class="mt-5 text-sm font-medium">Please note this offering is a courtesy service only and is not an objective step in the FUB revamp service we provide.</p>
          <hr>
        </div>
        <div class="flex flex-col items-center">
          <img :src="require('@/assets/images/training.svg')" width="120px" />
          <h4 class="text-2xl font-semibold underline mt-5">Training</h4>
        </div>
         <p class="mt-5 font-semibold">After the revamp is complete, we provide the following training:</p>
         <ul class="list-disc mt-5">
          <li>One-on-one personalized implementation call with a customer success manager</li>
          <li>In addition to the implementation call, one additional 30-minute training call is included at no extra cost.</li>
          <li>Two seats (users) will act as ambassadors from your company and will receive 90 days access to interactive group training sessions. This totals over {{ crm === 'Sierra' ? '50' : '40' }} hours of training available to you so you can have a full understanding of our setup and get all of your questions answered. These sessions will be recorded.</li>
          <li class="mt-2">Access to our video training library for in-depth training.</li>
          <li class="mt-2">A cheat sheet for quick reference on the revamp components. Our cheat sheet also lays out a clear plan for what to do daily in your {{ crm }} account.</li>
          <li v-if="fub" class="mt-2">Access to bi-weekly calls with Elena and other past clients every other Tuesday at 11 am Central. These calls are interactive and you receive lifetime access.</li>
         </ul>
      </div>
    </div>
  </section>
</template>
<script>
import { SETUP_TYPE } from '../../../constants'
import { getPricing } from '../../../pricing'
import RevampExtended from '@/components/common/revamp/RevampExtended.vue'
export default {
  props: ['crm'],
  components: {
    RevampExtended
  },
  data() {
    return {
      showMoreActionPlans: false,
      showMoreDealsAutomations: false
    }
  },
  computed: {
    pricing() {
      return getPricing(SETUP_TYPE.FUB_AGENT_REVAMP)
    },
    fub() {
      return this.crm === 'Follow Up Boss'
    },
    revampExtendedPricing() {
      return this.pricing.REVAMP_EXTENDED.toLocaleString('en')
    }
  }
}
</script>
<style scoped>
hr {
  @apply border-gray-300 my-5;
}
</style>
