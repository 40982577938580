<template>
  <TextureBackground>
    <header class="relative px-5 lg:fixed bg-black top-0 inset-x-0 flex border-b border-gray-800 z-50 lg:h-20 text-white" :style="{marginTop: bannerOffset }">
      <div class="max-w-7xl w-full mx-auto flex flex-col lg:flex-row justify-between items-center py-4">
        <a v-scroll-to="{ el: '#hero', offset: -80 }" class="cursor-pointer flex-shrink-0">
          <Logo />
        </a>
        <nav class="hidden md:flex items-center whitespace-nowrap mt-5 lg:mt-0">
          <div v-for="navItem, idx in navItems" :key="`nav_item_${idx}`">
            <div v-if="!navItem.subNavItems" class="ml-6 hover:underline" :class="{'hidden xl:block': navItem.showOnXLOnly}">
              <a v-if="navItem.scrollId" v-scroll-to="{ el: `#${navItem.scrollId}`, offset: -80 }">{{navItem.text}}</a>
              <router-link v-else :to="navItem.to">{{ navItem.text }}</router-link>
            </div>
            <div v-else class="relative sub-menu ml-6">
              <a class="cursor-default flex items-center">{{navItem.text}}<font-awesome-icon icon="fa-solid fa-chevron-down" class="ml-2 text-sm" /></a>
              <div class="hidden absolute left-0 bg-site-purple text-white whitespace-nowrap min-w-full">
                <router-link v-for="subNavItem, subNavIdx in navItem.subNavItems" :key="`sub_nav_item_${subNavIdx}`" :to="subNavItem.to" class="block py-2 px-6 cursor-pointer hover:underline first:border-t-0 border-t border-white">{{ subNavItem.text }}</router-link>
              </div>
            </div>
          </div>
          <a v-if="$listeners && $listeners.handleCTA" class="ml-6 bg-kts-gold text-black uppercase font-semibold py-3 px-7 cursor-pointer tracking-wider hover-bright inline-block" @click="$emit('handleCTA')">Get Started</a>
        </nav>
      </div>
    </header>
    <main class="lg:mt-20">
      <slot />
    </main>
    <footer class="py-8 px-6 text-white">
      <p class="text-center font-medium">© {{$copyrightDate}} by Kee Technology Solutions LLC</p>
    </footer>
  </TextureBackground>
</template>
<script>
import TextureBackground from '../common/TextureBackground.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    TextureBackground
  },
  props: {
    navItems: {
      type: Array,
      required: false,
    },
  },
  computed: {
    ...mapGetters(['bannerOffset']),
  }
}
</script>
<style scoped>
nav a {
  @apply cursor-pointer;
}
.sub-menu:hover div {
  display: block;
}
</style>