<template>
  <StandardPageLayout class="text-center">
    <p class="text-xl">This page has moved to our new admin portal!</p>
    <p class="mt-5">Click the link below to go to the new page.</p>
    <p class="mt-2">
      <a :href="href" class="link">{{ href }}</a>
    </p>
  </StandardPageLayout>
</template>
<script>
export default {
  props: ['portalPath'],
  computed: {
    href() {
      return `https://admin.keetechnology.com${this.portalPath}`
    }
  }
}
</script>