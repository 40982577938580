<template>
  <PromoLayout promo="Kathleen Black Waitlist" buttonText="Join the Waitlist!">
    <div class="text-center">
      <div class="bg-black text-xl text-white font-medium py-1 px-3 inline-block" >Transform your Follow Up Boss</div>
      <div class="text-xl uppercase mt-2">Boost your Success with the Ultimate Ecosystem</div>
    </div>
    <p class="mt-5">Kee Technology Solutions, in partnership with Kathleen Black Coaching, is excited to introduce a robust and fully integrated Follow Up Boss ecosystem.</p>
    <div class="mt-5 border rounded border-gray-400 max-w-sm mx-auto">
      <div class="flex items-center border-b border-gray-400 p-4">
        <font-awesome-icon icon="fa-solid fa-check" class="text-site-purple" />
        <div class="ml-5">
          <h3 class="font-semibold">Lead Nurturing</h3>
          <p class="text-sm font-light">Follow up protocols to drive conversions</p>
        </div>
      </div>
      <div class="flex items-center border-b border-gray-400 p-4">
        <font-awesome-icon icon="fa-solid fa-check" class="text-site-purple" />
        <div class="ml-5">
          <h3 class="font-semibold">Beyond Closing</h3>
          <p class="text-sm font-light">Follow up protocols for COI & VIP COI</p>
        </div>
      </div>
      <div class="flex items-center border-b border-gray-400 p-4">
        <font-awesome-icon icon="fa-solid fa-check" class="text-site-purple" />
        <div class="ml-5">
          <h3 class="font-semibold">Accountability</h3>
          <p class="text-sm font-light">Ensure protocol adherence</p>
        </div>
      </div>
      <div class="flex items-center p-4">
        <font-awesome-icon icon="fa-solid fa-check" class="text-site-purple" />
        <div class="ml-5">
          <h3 class="font-semibold">Comprehensive Checklists</h3>
          <p class="text-sm font-light">Streamline your workflow and transactions</p>
        </div>
      </div>
    </div>
    <p class="mt-5">You can also enhance the ecosystem with Kee Technology Solutions’ proven drip campaigns. These automated emails & SMS campaigns seamlessly compliment your active nurturing efforts with empathetic and personable messages that drive engagement and generate responses.</p>
    <p class="mt-5">Join the Kathleen Black ecosystem waitlist by providing your info below so you can be among the first to transform your Follow Up Boss.</p>
  </PromoLayout>
</template>
<script>
import PromoLayout from '../../layouts/PromoLayout.vue';

export default {
  components: { PromoLayout }
}
</script>