<template>
  <FormPageLayout>
    <p class="text-2xl font-semibold text-site-purple">Sierra Revamp</p>
    <p>Manual Sign-up</p>
    <FormSection title="Sales Representative">
      <SalesRepSelect v-model="formModel.salesRepresentative"
        :errorMessage="v$.formModel.salesRepresentative.$error ? v$.formModel.salesRepresentative.$errors[0].$message : ''" />
    </FormSection>
    <!-- Basic Info -->
    <FormSection title="Client Info">
      <VTextField :v="v$.formModel.basicInfo.firstName" placeholder="First Name" />
      <VTextField :v="v$.formModel.basicInfo.lastName" placeholder="Last Name" />
      <VTextField :v="v$.formModel.basicInfo.email" placeholder="Email" />
      <VTextField :v="v$.formModel.basicInfo.phone" placeholder="Phone Number" />
    </FormSection>
    <!-- Referral -->
    <FormSection title="Referral?">
      <div class="field" :class="{ 'field-block-error': v$.formModel.referral.$error }">
        <p v-if="v$.formModel.referral.$error" class="field-error text-xs text-red-500">
          {{ v$.formModel.referral.$errors[0].$message }}
        </p>
        <input type="radio" value="Yes" v-model="formModel.referral" />
        <label>Yes</label>
        <br />
        <input type="radio" value="No" v-model="formModel.referral" />
        <label>No</label>
      </div>

      <FormSection v-if="formModel.referral === 'Yes'" embed="true">
        <VTextField :v="v$.formModel.referredFrom" placeholder="Referred by" />
        <p class="my-2 font-medium">or affiliate referral selection</p>
        <AffiliateSelect v-model="formModel.affiliate"
          :errorMessage="v$.formModel.affiliate.$error ? v$.formModel.affiliate.$errors[0].$message : ''" />
      </FormSection>
    </FormSection>
    <!-- Fello -->
    <FormSection title="Include Fello Action Plans?" :helperText="`(Additional $${pricing.FELLO.toLocaleString('en')})`"
      chipText="Fello - Platform Pairing Add-On">
      <p class="text-sm font-medium">Note: By making this selection you agree that you are currently using Fello</p>
      <ToggleSwitch v-model="formModel.includeFelloActionPlans" />
    </FormSection>
    <!-- Conversion Monster -->
    <FormSection title="Include Conversion Monster Action Plans?"
      :helperText="`(Additional $${pricing.CONVERSION_MONSTER.toLocaleString('en')})`"
      chipText="Conversion Monster - Platform Pairing Add-On">
      <p class="text-sm font-medium">Note: By making this selection you agree that you are currently using Conversion
        Monster</p>
      <ToggleSwitch v-model="formModel.includeConversionMonsterActionPlans" />
    </FormSection>
    <!-- Ylopo Users -->
    <FormSection title="Include Ylopo Action Plans?" :helperText="`(Additional $${pricing.YLOPO.toLocaleString('en')})`"
      chipText="Ylopo - Platform Pairing Add-On">
      <p class="text-sm font-medium">Note: By making this selection you agree that you are currently using Ylopo</p>
      <ToggleSwitch v-model="formModel.includeYlopoActionPlans" />
    </FormSection>
    <!-- Revamp Extended Add-on -->
    <FormSection title='Include add-on "Revamp Extended"?'
      :helperText="`(Additional $${pricing.REVAMP_EXTENDED.toLocaleString('en')})`" chipText="Revamp Extended Add-On">
      <p class="link underline text-base font-normal" @click="showRevampExtendedModal = true"><font-awesome-icon
          icon="fa-solid fa-info-circle" /> see what's included</p>
      <ToggleSwitch v-model="formModel.includeRevampExtended" />
      <Modal v-if="showRevampExtendedModal" @close="showRevampExtendedModal = false">
        <RevampExtended crm="Sierra" :price="pricing.REVAMP_EXTENDED.toLocaleString('en')" />
      </Modal>
    </FormSection>
    <!-- Ponds Add-on -->
    <FormSection title='Include Ponds add-on?' :helperText="`(Additional $${pricing.PONDS_ADD_ON.toLocaleString('en')})`" chipText="Ponds Add-On">
      <p>9 best practice ponds w/ cooresponding action plans for managing new leads, re-engagements, re-inquiries, resurrections, vendors, off markets, and referral agents.</p>
      <ToggleSwitch v-model="formModel.includePondsAddOn" />
    </FormSection>
    <!-- Spanish Content -->
    <FormSection title="Include add-on Spanish Action Plans?"
      :helperText="`(Additional $${pricing.SPANISH_ACTION_PLANS.toLocaleString('en')})`" chipText="Spanish Add-On">
      <p class="text-sm font-medium">Note: We do not provide Spanish translations for our Canadian market material.</p>
      <p class="text-sm font-medium">Note: After the setup is complete, your review will be required before we can go
        live with it.</p>
      <p><a class="link underline text-base font-normal" @click="showSpanishContentModal = true"><font-awesome-icon
            icon="fa-solid fa-info-circle" /> more info</a></p>
      <SpanishContentModal v-if="showSpanishContentModal" @close="showSpanishContentModal = false" />
      <ToggleSwitch v-model="formModel.includeSpanishActionPlans" />

      <FormSection v-if="formModel.includeSpanishActionPlans" title="Default Language" embed="true">
        <p class="text-sm">Used when language preference is unknown for a lead</p>
        <VRadio :v="v$.formModel.defaultLanguage" :options="['English', 'Spanish']" />
      </FormSection>
    </FormSection>
    <!-- Lender Action Plans -->
    <FormSection title="Include add-on Lender Action Plans?"
      :helperText="`(Additional $${pricing.LENDER_ADD_ON.toLocaleString('en')})`" chipText="Lender Add-On">
      <p class="text-sm font-medium">Note: We do not provide Spanish translations for lender action plans at this time.
      </p>
      <p class="text-sm font-medium">Note: By making this selection you agree to the terms that the lenders in your
        Follow Up Boss account are in fact "Lender" type users. The lender action plans will not work otherwise.</p>
      <ToggleSwitch v-model="formModel.includeLenderAddOn" />
    </FormSection>
    <!-- Team Training -->
    <FormSection title="Private Team Training Call (1 hour)" :helperText="`(Additional $${pricing.PRIVATE_TEAM_TRAINING_CALL.toLocaleString('en')})`" chipText="Private Team Training Add-On">
      <p class="text-sm">One additional training call that your entire team may attend. * All other training calls are limited to two ambassadors from your team.</p>
      <ToggleSwitch v-model="formModel.includePrivateTeamTrainingCall" />
     </FormSection>
    <!-- Custom Work -->
    <FormSection title="Include Custom Work">
      <div class="field" :class="{ 'field-block-error': v$.formModel.customWorkNeeded.$error }">
        <p v-if="v$.formModel.customWorkNeeded.$error" class="field-error text-xs text-red-500">
          {{ v$.formModel.customWorkNeeded.$errors[0].$message }}
        </p>
        <input type="radio" value="Hourly Work" v-model="formModel.customWorkNeeded" />
        <label>Hourly Work</label> <span class="text-sm"></span>
        <br />
        <input type="radio" value="Flat Rate Work" v-model="formModel.customWorkNeeded" />
        <label>Flat Rate Work</label> <span class="text-sm"></span>
        <br />
        <input type="radio" value="No" v-model="formModel.customWorkNeeded" />
        <label>No</label>
      </div>
      <FormSection v-if="customWorkNeeded === 'Flat Rate Work'" title="Custom Work Amount" :embed="true">
        <VNumberField :v="v$.formModel.customWorkAmount" isDollarAmount="true" />
      </FormSection>
      <FormSection v-if="customWorkNeeded && customWorkNeeded !== 'No'" title="Custom Work Notes" :embed="true">
        <VTextArea :v="v$.formModel.customWorkNotes" placeholder="Add notes here..." />
      </FormSection>
    </FormSection>
    <!-- Project Price -->
    <FormSection title="Discount">
      <VNumberField :v="v$.formModel.discountAmount" :isDollarAmount="true" />
    </FormSection>
    <FormSection title="Project Price">
      <p class="text-lg font-medium mt-2">{{ totalDollarAmount }}</p>
      <p class="mt-2"><span class="link underline text-base font-normal"
          @click="showPriceBreakdownModal = true"><font-awesome-icon icon="fa-solid fa-info-circle" /> see price
          breakdown</span></p>
    </FormSection>
    <!-- Buy Now, Pay Later -->
    <FormSection v-if="bnpl" title="Buy now, pay later with Afterpay">
      <p @click="bnpl = false" class="cursor-pointer select-none link inline-block mt-5">Toggle back to Deposit Payment</p>
      <p class="mt-5 link select-none" @click="showAfterPayExplanation = !showAfterPayExplanation">
        Show afterpay explanation
        <font-awesome-icon :icon="showAfterPayExplanation ? 'fa-solid fa-chevron-up' : 'fa-solid fa-chevron-down'" />
      </p>
      <div v-if="showAfterPayExplanation" class="mt-5 bg-gray-200 p-4 gap-4 flex flex-col text-sm">
        <h2 class="font-semibold">Pay in 4 (interest-free)</h2>
        <p>$2,000 limit</p>
        <p>Spread payments over 4 biweekly interest-free installments. Payments are interest-free if they are made on time. The first payment is collected when setting up payment and the 3 remainder payments are due at 2-week intervals, spanning a total of 6 weeks.</p>
        <hr class="border-gray-400" />
        <h2 class="font-semibold">Pay monthly (with interest)</h2>
        <p>$4,000 limit</p>
        <p>6 or 12 month plans available.</p>
        <p class="text-xs">Pay Monthly is a simple interest loan with no late fees or an origination fee. The interest accrues daily based on the outstanding principal balance. Please refer to the Truth In Lending Disclosure in the Loan Agreement that will outline the total finance charge. </p>
      </div>
      <p class="font-semibold mt-10">Billing Address <span class="text-sm font-normal">(Recorded in Square for tax purposes)</span></p>
      <VTextField :v="v$.formModel.billing.addressLine1" placeholder="Address Line 1" />
      <VTextField :v="v$.formModel.billing.addressLine2" placeholder="Address Line 2" />
      <CountrySelect v-model="formModel.billing.country"
        :errorMessage="v$.formModel.billing.country.$error ? v$.formModel.billing.country.$errors[0].$message : ''" />
      <VTextField :v="v$.formModel.billing.city" placeholder="City" />
      <StateSelect v-model="formModel.billing.state"
        :errorMessage="v$.formModel.billing.state.$error ? v$.formModel.billing.state.$errors[0].$message : ''"
        :showProvinces="formModel.billing.country === 'Canada'" />
      <VTextField :v="v$.formModel.billing.zip"
        :placeholder="formModel.billing.country === 'Canada' ? 'Postal Code' : 'Zip Code'" />
      <div class="mt-5">
        <button @click="submit" :disabled="!canSubmit" class="button">Submit</button>
      </div>
    </FormSection>
    <!-- Deposit Payment -->
    <FormSection title="Payment">
      <p class="mt-5 cursor-pointer select-none inline-block link" @click="bnpl = true">Due now is a deposit of {{ depositDollarAmount }} USD</p>
      <div class="bg-gray-200 mt-5 p-5">
        <p>The deposit amount is half of the total project price {{ totalDollarAmount }}.</p>
        <p class="mt-2">A balance payment of {{ depositDollarAmount }} will be due within 2 weeks after the setup is
          complete.</p>
        <p class="mt-2"><strong>NOTE:</strong> The two week window to pay balance <strong>IS NOT CONTINGENT</strong>
          on a follow up call with you after the setup. Two weeks is a hard deadline.</p>
      </div>
      <p class="font-semibold mt-10">Billing Information</p>
      <VTextField :v="v$.formModel.billing.firstName" placeholder="First Name" />
      <VTextField :v="v$.formModel.billing.lastName" placeholder="Last Name" />
      <VTextField :v="v$.formModel.billing.email" placeholder="Email" />
      <VTextField :v="v$.formModel.billing.addressLine1" placeholder="Address Line 1" />
      <VTextField :v="v$.formModel.billing.addressLine2" placeholder="Address Line 2" />
      <CountrySelect v-model="formModel.billing.country"
        :errorMessage="v$.formModel.billing.country.$error ? v$.formModel.billing.country.$errors[0].$message : ''" />
      <VTextField :v="v$.formModel.billing.city" placeholder="City" />
      <StateSelect v-model="formModel.billing.state"
        :errorMessage="v$.formModel.billing.state.$error ? v$.formModel.billing.state.$errors[0].$message : ''"
        :showProvinces="formModel.billing.country === 'Canada'" />
      <VTextField :v="v$.formModel.billing.zip"
        :placeholder="formModel.billing.country === 'Canada' ? 'Postal Code' : 'Zip Code'" />
      <VTextField :v="v$.formModel.billing.phone" placeholder="Phone" />
      <p class="mt-5 font-semibold">Card Information</p>
      <SquarePayment ref="squarePayment" />
      <button @click="submit" :disabled="!canSubmit" class="button mt-5">Submit and pay {{depositDollarAmount}} Deposit</button>
      <p v-if="v$.$error" class="mt-2 text-red-500">
        Oops... There is an issue with the form. Check fields for errors.
      </p>
    </FormSection>
    <PriceBreakdownModal
      v-if="showPriceBreakdownModal"
      @close="showPriceBreakdownModal = false"
      :lineItems="lineItems"
      :total="total"
    />
    <SubmittingModal v-if="isSubmitting" @close="(isSubmitting = false)" :loading="loading" :errorMessage="error"
      :noPayment="true">
      <template #success>
        <div>
          <p class="text-3xl text-site-purple font-semibold">Sign up complete!</p>
          <p class="text-xl pt-5" v-if="bnpl">Info was submitted successfully.</p>
          <p class="text-xl pt-5" v-else>Info was submitted successfully and the deposit payment of <strong class="font-semibold">{{ depositDollarAmount }}</strong> has been processed.</p>
        </div>
      </template>
    </SubmittingModal>
  </FormPageLayout>
</template>
<script>
import { required, requiredIf, formValidationMixin } from '@/mixins/formValidateMixin'
import AffiliateSelect from '@/components/common/form/AffiliateSelect'
import SalesRepSelect from '../../common/form/SalesRepSelect'
import _pick from 'lodash/pick'
import SpanishContentModal from "../setup-form/SpanishContentModal";
import { SETUP_TYPE } from '../../../constants';
import RevampExtended from '../../common/revamp/RevampExtended.vue';
import pricingMixin from '@/mixins/pricingMixin'

export default {
  mixins: [formValidationMixin, pricingMixin],
  components: {
    SalesRepSelect,
    SpanishContentModal,
    AffiliateSelect,
    RevampExtended
  },
  data() {
    return {
      loading: false,
      error: '',
      isSubmitting: false,
      bnpl: false,
      showAfterPayExplanation: false,
      showSpanishContentModal: false,
      showPriceBreakdownModal: false,
      showRevampExtendedModal: false,
      formModel: {
        salesRepresentative: "",
        basicInfo: {
          firstName: "",
          lastName: "",
          email: "",
          phone: ""
        },
        referral: "",
        referredFrom: "",
        affiliate: "",
        includeLenderAddOn: false,
        includeConversionMonsterActionPlans: false,
        includeYlopoActionPlans: false,
        includeSpanishActionPlans: false,
        includePondsAddOn: false,
        includeRevampExtended: false,
        includeFelloActionPlans: false,
        defaultLanguage: "",
        billing: {
          firstName: "",
          lastName: "",
          email: "",
          addressLine1: "",
          addressLine2: "",
          country: "",
          city: "",
          state: "",
          zip: "",
          phone: "",
        },
        discountAmount: "",
        customWorkNeeded: "No",
        customWorkAmount: "",
        customWorkNotes: "",
        includePrivateTeamTrainingCall: false,
      },
    };
  },
  validations() {
    return {
      formModel: {
        salesRepresentative: { required },
        basicInfo: {
          firstName: { required },
          lastName: { required },
          email: { required },
          phone: { required }
        },
        referral: {},
        referredFrom: {},
        affiliate: {},
        defaultLanguage: { required: requiredIf(this.formModel.includeSpanishActionPlans) },
        discountAmount: {},
        customWorkNeeded: { required },
        customWorkAmount: { required: requiredIf(this.customWorkNeeded === 'Flat Rate Work') },
        customWorkNotes: { required: requiredIf(this.customWorkNeeded && this.customWorkNeeded !== 'No') },
        billing: {
          firstName: { required: requiredIf(!this.bnpl) },
          lastName: { required: requiredIf(!this.bnpl) },
          email: { required: requiredIf(this.bnpl) },
          addressLine1: { required },
          addressLine2: {},
          country: { required },
          city: { required },
          state: { required },
          zip: { required},
          phone: { required: requiredIf(!this.bnpl) }
        },
      },
    };
  },
  computed: {
    setupType() {
      return SETUP_TYPE.SIERRA
    },
    customWorkAmount() {
      return Number(this.formModel.customWorkAmount)
    },
    discountAmount() {
      return Number(this.formModel.discountAmount)
    },
    totalDollarAmount() {
      return `$${this.total.toLocaleString('en')}`
    },
    depositDollarAmount() {
      return `$${this.depositAmount.toLocaleString('en')}`
    },
    canSubmit() {
      return !this.isSubmitting;
    },
    customWorkNeeded() {
      return this.formModel.customWorkNeeded
    },
    discountProvided() {
      return !!this.formModel.discountAmount
    },
  },
  watch: {
    customWorkNeeded(newVal) {
      if (newVal === 'No') {
        this.formModel.customWorkAmount = "",
          this.formModel.customWorkNotes = ""
      } else if (newVal === 'Hourly Work') {
        this.formModel.customWorkAmount = ""
      }
    }
  },
  methods: {
    buildNotes() {
      return _pick(this.formModel, [
        'includeSpanishActionPlans',
        'includeConversionMonsterActionPlans',
        'includeYlopoActionPlans',
        'includeRevampExtended',
        'includeFelloActionPlans',
        'includePondsAddOn',
        'defaultLanguage',
        'includeLenderAddOn',
        'customWorkNeeded',
        'customWorkAmount',
        'customWorkNotes',
        'discountAmount',
        'includePrivateTeamTrainingCall',
      ])
    },
    async submit() {
      if (!(await this.v$.$validate())) return

      try {
        this.error = ''
        const notes = this.buildNotes();
        const { locationId, sourceId } = this.bnpl ? {} : await this.$refs.squarePayment.tokenize()
        const payment = this.bnpl ? { billing: this.formModel.billing } : {
          note: 'Deposit Payment',
          isDeposit: true,
          billing: this.formModel.billing,
          locationId,
          sourceId
        }
        this.loading = true;
        this.isSubmitting = true;

        await this.$service.manualSignUp({
          basicInfo: this.formModel.basicInfo,
          notes,
          referredFrom: this.formModel.referredFrom,
          affiliate: this.formModel.affiliate,
          meta: {
            setupType: this.setupType,
            bnpl: this.bnpl,
            includeSpanishActionPlans: this.formModel.includeSpanishActionPlans,
            includeLenderAddOn: this.formModel.includeLenderAddOn,
            customWorkNeeded: this.customWorkNeeded,
            discountProvided: this.discountProvided,
            salesRepresentative: this.formModel.salesRepresentative,
            customWorkAmount: this.customWorkAmount,
            discountAmount: this.discountAmount,
            legacyPricing: this.legacyPricing,
            includeRevampExtended: this.formModel.includeRevampExtended,
            includePondsAddOn: this.formModel.includePondsAddOn,
            includeConversionMonsterActionPlans: this.formModel.includeConversionMonsterActionPlans,
            includeYlopoActionPlans: this.formModel.includeYlopoActionPlans,
            includeFelloActionPlans: this.formModel.includeFelloActionPlans,
            includePrivateTeamTrainingCall: this.formModel.includePrivateTeamTrainingCall,
          },
          payment
        })

        this.loading = false
      } catch (e) {
        this.loading = false
        this.error = this.$service.getErrorMessage(e)
      }
    },
  },
};
</script>
<style scoped>
label {
  @apply mr-2;
}

.field {
  @apply mt-2;
}

input[type="radio"] {
  @apply mr-2;
}

.field-block-error {
  @apply inline-block border border-red-500 p-2 rounded;
}
</style>
