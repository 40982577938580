<template>
  <Modal @close="$emit('close')">
    <h1 class="text-2xl text-center mt-10">Price Breakdown</h1>
    <table class="mt-10 max-w-xl w-full mx-auto">
      <tbody>
        <tr v-for="lineItem, idx in lineItems" :key="`line-item-${idx}`">
          <td class="border-b border-gray-300 py-2 px-3 w-1/2">{{ lineItem.name }}</td>
          <td class="border-b border-gray-300 py-2 px-3 w-1/2 text-right">${{ lineItem.price.toLocaleString('en') }}</td>
        </tr>
        <tr class="font-semibold">
          <td class="py-2 px-3 w-1/2">Total</td>
          <td class="py-2 px-3 w-1/2 text-right">(USD) ${{ total.toLocaleString('en') }}</td>
        </tr>
      </tbody>
    </table>
    <div class="flex justify-center mt-5">
      <button class="button mt-5" @click="$emit('close')">Close</button>
    </div>
  </Modal>
</template>
<script>
export default {
  props: [
    'lineItems',
    'total'
  ],
}
</script>