<template>
  <FormPageLayout>
    <p class="text-2xl font-semibold">Thanks for signing up!</p>
    <p class="mt-5"><strong>PLEASE READ - Custom work might be required</strong></p>
    <p>If your team currently operates with multiple Transaction Coordinators who don't specialize in one part of the transaction pipeline and work with multiple agents then we need to provide you a custom build with custom pricing. Please <a :href="calendlyUrl" class="link">schedule a call here</a> to get signed up instead of submitting this form.</p>
    <p class="mt-5"><strong>Our Transaction Coordinator build is for a USA audience only.</strong></p>
    <p class="mt-5">We will need the following information before we can start the transaction coordinator
      build.</p>
    <!-- Basic Info -->
    <FormSection title="Your Info">
      <VTextField :v="v$.formModel.basicInfo.firstName" placeholder="First Name" />
      <VTextField :v="v$.formModel.basicInfo.lastName" placeholder="Last Name" />
      <VTextField :v="v$.formModel.basicInfo.email" placeholder="Email" />
      <VTextField :v="v$.formModel.basicInfo.phone" placeholder="Phone Number" />
    </FormSection>
    <!-- Follow Up Boss Credentials -->
    <FormSection title="Follow Up Boss Credentials" helperText="(Owner Account)">
      <VTextField :v="v$.formModel.crmUsername" placeholder="Username" autocomplete="off" />
      <VTextField :v="v$.formModel.crmPassword" placeholder="Password" autocomplete="off" />
    </FormSection>
    <!-- Company -->
    <FormSection title="Company Name">
      <VTextField :v="v$.formModel.company" placeholder="Jane Doe Group - KW Realty" class="text-sm" />
    </FormSection>
    <!-- Online Reviews -->
    <FormSection title="Online Reviews" helperText="(Please provide any URLs to your online reviews i.e Google, Zillow, Facebook etc.)">
      <div v-for="(onlineReview, idx) in formModel.onlineReviews" :key="`online_review_${idx}`">
        <input type="text" v-model="onlineReview.url" class="form-input" placeholder="http://review-url.com" />
        <font-awesome-icon v-if="idx !== 0" class="cursor-pointer ml-2" @click="removeOnlineReview(idx)" icon="fa-solid fa-times" />
      </div>
      <p class="mt-5 font-semibold text-sm">Have more online review URLs?</p>
      <a @click="addOnlineReview" class="text-site-purple font-semibold mt-1 inline-block underline cursor-pointer">
        <font-awesome-icon icon="fa-solid fa-plus-circle" />
        Add Online Review URL
      </a>
    </FormSection>
    <!-- Business Address -->
    <FormSection title="Business Address">
      <VTextField :v="v$.formModel.businessAddress.addressLine1" placeholder="Address Line 1" />
      <VTextField :v="v$.formModel.businessAddress.addressLine2" placeholder="Address Line 2" />
      <CountrySelect v-model="formModel.businessAddress.country" :errorMessage="v$.formModel.businessAddress.country.$error ? v$.formModel.businessAddress.country.$errors[0].$message : ''" />
      <VTextField :v="v$.formModel.businessAddress.city" placeholder="City" />
      <StateSelect v-model="formModel.businessAddress.state" :errorMessage="v$.formModel.businessAddress.state.$error ? v$.formModel.businessAddress.state.$errors[0].$message : ''" :showProvinces="formModel.businessAddress.country === 'Canada'" />
      <VTextField :v="v$.formModel.businessAddress.zip" :placeholder="formModel.businessAddress.country === 'Canada' ? 'Postal Code' : 'Zip Code'" />
    </FormSection>
    <!-- Servicing Multiple Markets -->
    <FormSection title="Do you service more than one market (multiple states)?">
      <VRadio :v="v$.formModel.multiMarketServicing" />
    </FormSection>
    <!-- ETA State -->
    <FormSection title="Who handles closing?">
      <VRadio :v="v$.formModel.closing" :options="['Escrow', 'Title', 'Attorney']" />
    </FormSection>
    <!-- Deposit Holding -->
    <FormSection title="Where are buyer deposits held?">
      <DepositSelect v-model="formModel.depositHolding" :errorMessage="v$.formModel.depositHolding.$error ? v$.formModel.depositHolding.$errors[0].$message : ''" />
    </FormSection>
    <FormSection v-if="formModel.depositHolding === 'Other (specify)'" title="Specify buyer deposit holding for your state">
      <VTextField :v="v$.formModel.depositHoldingSpecify" />
    </FormSection>
    <!-- Team of Agents -->
    <FormSection title="Do you have a team of agents that work under you?">
      <VRadio :v="v$.formModel.teamOfAgents" />
    </FormSection>
    <!-- Team Leader -->
    <FormSection v-if="formModel.teamOfAgents === 'Yes'" title="Congrats emails should send from? (Please specify the team leader's name below)">
      <VTextField :v="v$.formModel.teamLeader" placeholder="Name of team leader" />
    </FormSection>
    <!-- Transaction Coordinator -->
    <FormSection title="Do you have a transaction coordinator?">
      <VRadio :v="v$.formModel.transactionCoordinator" />
    </FormSection>
    <!-- TC List -->
    <FormSection v-if="formModel.transactionCoordinator === 'Yes'" title="List transaction coordinators below">
      <div v-for="tc in formModel.tcList" :key="tc.name" class="mt-2 flex items-center bg-gray-100 p-5 shadow max-w-3xl text-sm">
        <div class="flex-grow"><strong>{{ tc.name }}</strong> works with <strong>{{ tc.type }}</strong></div>
        <font-awesome-icon icon="fa-solid fa-trash" class="ml-5 text-red-500 cursor-pointer" @click="deleteTC(tc.name)" />
      </div>
      <a @click="stageTC" class="
        text-site-purple
        font-semibold
        mt-1
        inline-block
        underline
        cursor-pointer
      ">
        <font-awesome-icon icon="fa-solid fa-plus-circle" />
        Add Transaction Coordinator
      </a>
      <Modal v-if="showTCModal" size="sm" @close="showTCModal = false">
        <div class="flex flex-col items-center">
          <p class="text-center text-lg font-semibold">Tell us about the transaction coordinator</p>
          <input type="text" v-model="stagedTC.name" placeholder="Name" class="mt-5 form-input" />
          <p class="mt-5">works with...</p>
          <select v-model="stagedTC.type" class="mt-5 form-input" style="appearance: revert;">
            <option disabled value="">select work type</option>
            <option>Listing Coordination</option>
            <option>Selling Transactions</option>
            <option>Buying Transactions</option>
            <option>All - Listing Coordination + Selling/Buying Transactions</option>
          </select>
          <button class="button mt-5" @click="addTC" :disabled="stagedTC.name === '' || stagedTC.type === ''">Add</button>
        </div>
      </Modal>
    </FormSection>
    <!-- ISA Compensation -->
    <FormSection v-if="formModel.transactionCoordinator === 'Yes'" title="Does your team consist of ISAs that receive compensation from closed transactions?">
      <VRadio :v="v$.formModel.isaCompensation" />
    </FormSection>
    <!-- Stage setup -->
    <FormSection>
      <p class="font-semibold text-lg">Setting up stages for the revamp</p>
      <div class="field" :class="{ 'field-block-error': v$.formModel.stageSetup.$error }">
        <p v-if="v$.formModel.stageSetup.$error" class="field-error text-xs text-red-500">
          {{ v$.formModel.stageSetup.$errors[0].$message }}
        </p>
        <input type="radio" :value="stageSetupTypes.DEFAULT" v-model="formModel.stageSetup" />
        <label>Use the default stages provided by the TC Build (recommended for most)</label>
        <br />
        <input type="radio" :value="stageSetupTypes.ZILLOW_TWO_WAY_SYNC" v-model="formModel.stageSetup" />
        <label>I want to use the Zillow Stages via the 2-way sync with Zillow - more info <a class="link"
            href="https://help.followupboss.com/hc/en-us/articles/8895957403927" target="_blank">here</a></label>
        <br />
        <input type="radio" :value="stageSetupTypes.CUSTOM" v-model="formModel.stageSetup" />
        <label>I would like to opt out of using the default stages used by the TC Build. Instead, I have a requirement
          that the build be customized to use predefined stages that I will provide to Kee Technology Solutions. I
          acknowledge that I must provide names of my stages before work on the revamp can be started and <strong>I
            agree to pay a ${{ customStagesPricing }} customization fee.</strong> I acknowledge that my review is
          required before going live with the build out. (Note: We will reach out to you after your submission to obtain
          a list of stages we can use. We will reach back out after the setup is complete to review the setup with you
          before we go live with it.)</label>
      </div>
    </FormSection>
    <!-- Setup activation notice -->
    <FormSection title="Activating your new setup">
      <p class="mt-5">After the setup of your new action plans and automations is complete, we will notify you via
        email. At this stage, you can either approve immediate activation or opt for a review period to evaluate the
        setup before it goes live.</p>
    </FormSection>
    <FormSection title="Terms Agreement">
      <div class="mt-5">
        <div :class="{ 'field-block-error': v$.formModel.agreeToConditions.$error }">
          <p v-if="v$.formModel.agreeToConditions.$error" class="field-error text-xs text-red-500">{{ v$.formModel.agreeToConditions.$errors[0].$message }}</p>
          <input type="checkbox" v-model="formModel.agreeToConditions" true-value="agree" false-value="" />
          <label class="ml-2">I agree to <a class="link text-base font-normal" @click="showConditionAgreement = true"><font-awesome-icon icon="fa-solid fa-info-circle" /> these conditions</a></label>
        </div>
      </div>
      <ConditionAgreementModal type="tc-assist" v-if="showConditionAgreement" @close="showConditionAgreement = false" />
    </FormSection>
    <!-- Payment -->
    <FormSection>
      <!-- Project Price -->
      <div class="inline-block border p-10 bg-gray-100 text-gray-700">
        <p class="text-xl font-medium">Project Price</p>
        <p class="text-lg font-medium mt-2">{{ totalDollarAmount }}</p>
        <div class="mt-5 text-sm w-72">
          <p><strong>Breakdown</strong></p>
          <p class="border-b py-2 flex justify-between"><span>Base Price:</span><span>${{ basePrice }}</span></p>
          <p v-if="customizeStages" class="border-b py-2 flex justify-between"><span>Custom Stages:</span><span>${{ customStagesPricing }}</span></p>
          <div v-if="formModel.tcList.length > 0">
            <p class="border-b py-2 flex justify-between"><span>First TC Seat:</span><span>${{ pricing.FIRST_TC_SEAT.toLocaleString('en') }}</span></p>
            <p class="border-b py-2 flex justify-between" v-for="item, idx in formModel.tcList.slice(1)" :key="idx">
              <span>Add TC Seat:</span><span>${{ pricing.ADDITIONAL_TC_SEAT.toLocaleString('en') }}</span>
            </p>
          </div>
        </div>
      </div>
      <div class="mt-5">
        <p class="text-2xl font-semibold">
          Deposit Payment
        </p>
        <p class="text-lg font-semibold mt-5">
          Deposit Amount: {{ depositDollarAmount }} <span class="text-sm font-normal">(USD)</span>
        </p>
        <p class="mt-2 text-sm">Due now is a deposit of half the total project price.</p>
        <p class="text-sm mt-2">A balance payment of
          {{ depositDollarAmount }} will be due within 2 weeks after the setup is complete.<br /><strong>PLEASE
            NOTE:</strong> The two week window to pay balance <strong>IS NOT CONTINGENT</strong> on a follow up call
          with you after the setup. Two weeks is a hard deadline.</p>
      </div>
      <p class="font-semibold mt-10">Billing Information</p>
      <VTextField :v="v$.formModel.billing.firstName" placeholder="First Name" />
      <VTextField :v="v$.formModel.billing.lastName" placeholder="Last Name" />
      <VTextField :v="v$.formModel.billing.email" placeholder="Email" />
      <VTextField :v="v$.formModel.billing.addressLine1" placeholder="Address Line 1" />
      <VTextField :v="v$.formModel.billing.addressLine2" placeholder="Address Line 2" />
      <CountrySelect v-model="formModel.billing.country"
        :errorMessage="v$.formModel.billing.country.$error ? v$.formModel.billing.country.$errors[0].$message : ''" />
      <VTextField :v="v$.formModel.billing.city" placeholder="City" />
      <StateSelect v-model="formModel.billing.state"
        :errorMessage="v$.formModel.billing.state.$error ? v$.formModel.billing.state.$errors[0].$message : ''"
        :showProvinces="formModel.billing.country === 'Canada'" />
      <VTextField :v="v$.formModel.billing.zip"
        :placeholder="formModel.billing.country === 'Canada' ? 'Postal Code' : 'Zip Code'" />
      <VTextField :v="v$.formModel.billing.phone" placeholder="Phone" />
      <p class="mt-5 font-semibold">Card Information</p>
      <SquarePayment ref="squarePayment" />
      <button @click="submit" :disabled="isSubmitting" class="button mt-5">
        Submit and pay {{ depositDollarAmount }}
      </button>
      <p v-if="v$.$error" class="mt-2 text-red-500">
        Oops... There is an issue with the form. Check fields for errors.
      </p>
    </FormSection>
    <SubmittingModal
      v-if="isSubmitting"
      @close="(isSubmitting = false)"
      :loading="loading"
      :errorMessage="error"
      :showNextSteps="true"
      :receiptUrl="receiptUrl"
    >
      <template #success>
        <div>
          <p class="text-3xl text-site-purple font-semibold">Sign up complete!</p>
          <p class="text-xl pt-5">Your information has been submitted successfully and the payment of <strong class="font-semibold">{{depositDollarAmount}}</strong> has been processed.</p>
        </div>
      </template>
    </SubmittingModal>
  </FormPageLayout>
</template>
<script>
import { required, requiredIf, formValidationMixin } from '@/mixins/formValidateMixin'
import { STAGE_SETUP_TYPES, SETUP_TYPE, CALENDLY_CALENDARS } from '../../../constants';
import { getPricing } from '@/pricing'
import DepositSelect from './DepositSelect.vue';
import _pick from 'lodash/pick'
import { mapGetters } from 'vuex'

class TransactionCoordinator {
  constructor({ name, type }) {
    this.name = name
    this.type = type
  }
}

const newOnlineReview = {
  url: ""
};

export default {
  mixins: [formValidationMixin],
  components: { DepositSelect },
  data() {
    return {
      loading: false,
      error: "",
      formModel: {
        basicInfo: {
          firstName: "",
          lastName: "",
          email: "",
          phone: ""
        },
        crmUsername: "",
        crmPassword: "",
        company: "",
        onlineReviews: [{ ...newOnlineReview }],
        businessAddress: {
          addressLine1: "",
          addressLine2: "",
          country: "",
          city: "",
          state: "",
          zip: "",
        },
        multiMarketServicing: "",
        closing: "",
        depositHolding: "",
        depositHoldingSpecify: "",
        teamOfAgents: "",
        teamLeader: "",
        transactionCoordinator: "",
        tcList: [],
        isaCompensation: "",
        stageSetup: "",
        billing: {
          firstName: "",
          lastName: "",
          email: "",
          addressLine1: "",
          addressLine2: "",
          country: "",
          city: "",
          state: "",
          zip: "",
          phone: "",
        },
        agreeToConditions: "",
      },
      receiptUrl: "",
      stagedTC: null,
      isSubmitting: false,
      showTCModal: false,
      showConditionAgreement: false,
    }
  },
  validations() {
    return {
      formModel: {
        basicInfo: {
          firstName: { required },
          lastName: { required },
          email: { required },
          phone: { required }
        },
        crmUsername: { required },
        crmPassword: { required },
        company: { required },
        businessAddress: {
          addressLine1: { required },
          addressLine2: {},
          country: { required },
          city: { required },
          state: { required },
          zip: { required },
        },
        multiMarketServicing: { required },
        closing: { required },
        depositHolding: { required },
        depositHoldingSpecify: { required: requiredIf(this.formModel.depositHolding === 'Other (specify)') },
        teamOfAgents: { required },
        teamLeader: { required: requiredIf(this.formModel.teamOfAgents === 'Yes') },
        transactionCoordinator: { required },
        isaCompensation: { required: requiredIf(this.formModel.transactionCoordinator === 'Yes') },
        stageSetup: { required },
        billing: {
          firstName: { required },
          lastName: { required },
          email: {},
          addressLine1: { required },
          addressLine2: {},
          country: { required },
          city: { required },
          state: { required },
          zip: { required },
          phone: {}
        },
        agreeToConditions: { required },
      }
    }
  },
  computed: {
    ...mapGetters(['affiliateName']),
    setupType() {
      return SETUP_TYPE.TC_ASSIST
    },
    calendlyUrl() {
      return `https://calendly.com/${CALENDLY_CALENDARS.TC_ASSIST_DISCOVERY}`
    },
    pricing() {
      return getPricing(this.setupType)
    },
    basePrice() {
      return this.pricing.BASE.toLocaleString('en')
    },
    stageSetupTypes() {
      return STAGE_SETUP_TYPES
    },
    customizeStages() {
      return this.formModel.stageSetup === STAGE_SETUP_TYPES.CUSTOM
    },
    customStagesPricing() {
      return this.pricing.CUSTOM_STAGES
    },
    totalAmount() {
      let amount = this.pricing.BASE
      if (this.tcCount > 0) {
        amount += this.pricing.FIRST_TC_SEAT
        const additionalSeats = this.tcCount - 1
        if (additionalSeats > 0) {
          amount += additionalSeats * this.pricing.ADDITIONAL_TC_SEAT
        }
      }
      if (this.customizeStages) {
        amount += this.customStagesPricing
      }
      return amount
    },
    depositAmount() {
      return this.totalAmount/2
    },
    totalDollarAmount() {
      return `$${this.totalAmount.toLocaleString('en')}`
    },
    depositDollarAmount() {
      return `$${this.depositAmount.toLocaleString('en')}`
    },
    tcCount() {
      return this.formModel.tcList.length
    },
  },
  methods: {
    stageTC() {
      this.stagedTC = new TransactionCoordinator({ name: "", type: "" })
      this.showTCModal = true
    },
    addTC() {
      this.formModel.tcList.push(this.stagedTC)
      this.showTCModal = false
    },
    deleteTC(tcName) {
      this.formModel.tcList = this.formModel.tcList.filter(({ name }) => name !== tcName)
    },
    addOnlineReview() {
      this.formModel.onlineReviews.push({ ...newOnlineReview });
    },
    removeOnlineReview(idx) {
      this.formModel.onlineReviews.splice(idx, 1);
    },
    buildNotes() {
      return _pick(this.formModel, [
        'company',
        'businessAddress',
        'onlineReviews',
        'multiMarketServicing',
        'closing',
        'depositHolding',
        'depositHoldingSpecify',
        'teamOfAgents',
        'teamLeader',
        'transactionCoordinator',
        'tcList',
        'isaCompensation',
        'stageSetup',
      ])
    },
    async submit() {
      if (!(await this.v$.$validate())) return

      try {
        const notes = this.buildNotes()
        const billing = this.formModel.billing
        const { locationId, sourceId } = await this.$refs.squarePayment.tokenize()
        this.error = ""
        this.isSubmitting = true
        this.loading = true

        const { receiptUrl } = await this.$service.createSetup({
          keeId: null,
          affiliate: this.affiliateName,
          basicInfo: this.formModel.basicInfo,
          crmPassword: this.formModel.crmPassword,
          crmUsername: this.formModel.crmUsername,
          notes,
          meta: {
            setupType: this.setupType,
            customStages: this.customizeStages,
            tcCount: this.tcCount
          },
          payment: {
            note: 'TC Assist - Deposit',
            isDeposit: true,
            billing,
            locationId,
            sourceId,
          },
          businessAddress: this.formModel.businessAddress
        })
        
        this.receiptUrl = receiptUrl
        this.loading = false
      } catch (e) {
        this.error = this.$service.getErrorMessage(e)
        this.loading = false
      }
    },
  }
}
</script>
<style scoped>
label {
  @apply mr-2;
}

.field {
  @apply mt-2;
}

input[type="radio"] {
  @apply mr-2;
}

.field-block-error {
  @apply inline-block border border-red-500 p-2 rounded;
}
</style>