<template>
  <div>
    <div class="text-center">
      <h1 class="text-3xl font-semibold">🎉 Revamp Extended 🎉</h1>
      <h2 class="text-xl">Optional Add-on - ${{ price }}</h2>
    </div>
    <p class="mt-5">Take it beyond our baseline offering with <strong>Revamp Extended</strong>. This add on includes the following action plans</p>
    <ul v-if="crm === 'Follow Up Boss'" class="list-disc mt-5">
      <li><strong>Recruiting Drip</strong> - 1 year of emails (approx. 17 emails)</li>
      <li><strong>Nurture Expired Drip</strong> - 2 years of emails (approx. 24 emails) (not intended for cold lead reach out. MUST have made a connection with lead)</li>
      <li><strong>Nurture FSBO Drip</strong> - 2 years of emails (approx. 26 emails) (not intended for cold lead reach out. MUST have made a connection with lead)</li>
      <li><strong>Luxury Nurture Buyer</strong> - 3 years of emails (approx. 40 emails)</li>
      <li><strong>Luxury Nurture Seller</strong> - 3 years of emails (approx. 36 emails)</li>
      <li><strong>Luxury Nurture Buyer + Seller</strong> - 6 years of emails (approx. 74 emails)</li>
      <li><strong>New Construction Lead</strong> - 2 years of emails (approx. 31 emails - Not yet available in Spanish)</li>
      <li><strong>Nurture New Construction</strong> - 2 years of emails (approx. 32 emails - Not yet available in Spanish)</li>
      <li><strong>Nurture Probate</strong> - 3 years of emails (approx. 31 emails - Not yet available in Spanish)</li>
      <li><strong>New Investor Buyer</strong> - 3 years of emails (approx. 32 emails - Not yet available in Spanish)</li>
      <li><strong>Nurture Investor Buyer</strong> - 3 years of emails (approx. 40 emails - Not yet available in Spanish)</li>
      <li><strong>New Land Buyer Lead</strong> - 2 years of emails (approx. 29 emails - Not yet available in Spanish)</li>
      <li><strong>Nurture Land Buyer Lead</strong> - 3 years of emails (approx. 40 emails - Not yet available in Spanish)</li>
      <li><strong>Nurture Investment Property Owner</strong> - 3 years of emails (approx. 46 emails - Not yet available in Spanish)</li>
    </ul>
    <ul v-else class="list-disc mt-5">
      <li><strong>Recruiting Drip</strong> - 1 year long (approx. 17 emails & 16 texts)</li>
      <li><strong>Nurture Expired Drip</strong> - 1.5 years long (approx. 24 emails & 17 texts) (not intended for cold lead reach out. MUST have made a connection with lead)</li>
      <li><strong>Nurture FSBO Drip</strong> - 1.5 years long (approx. 25 emails & 15 texts) (not intended for cold lead reach out. MUST have made a connection with lead)</li>
      <li><strong>Luxury Nurture Buyer</strong> - 3 years long (approx. 40 emails & 20 texts)</li>
      <li><strong>Luxury Nurture Seller</strong> - 3 years long (approx. 36 emails & 23 texts)</li>
      <li><strong>Luxury Nurture Buyer + Seller</strong> - 6 years long (approx. 74 emails & 37 texts)</li>
      <li><strong>New Construction Lead</strong> - 2 years long (approx. 31 emails & 22 texts)</li>
      <li><strong>Nurture New Construction</strong> - 2.5 years long (approx. 32 emails & 17 texts)</li>
      <li><strong>Nurture Probate</strong> - 2.5 years long (approx. 32 emails & 17 texts - Not yet available in Spanish)</li>
      <li><strong>New Investor Buyer</strong> - 2 years long (approx. 32 emails & 23 texts)</li>
      <li><strong>Nurture Investor Buyer</strong> - 3 years long (approx. 40 emails & 19 texts)</li>
      <li><strong>New Land Buyer Lead</strong> - 2 years long (approx. 29 emails & 22 texts)</li>
      <li><strong>Nurture Land Buyer Lead</strong> - 3 years long (approx. 40 emails & 22 texts - Not yet available in Spanish)</li>
      <li><strong>Nurture Investment Property Owner</strong> - 3 years of emails (approx. 46 emails & 20 texts - Not yet available in Spanish)</li>
    </ul>
  </div>
</template>
<script>
export default {
  props: ['crm', 'price']
}
</script>