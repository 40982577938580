<template>
  <section class="py-20 px-6 text-white" id="service">
    <div class="container mx-auto">
      <h3 class="text-center text-4xl font-bold">What's Included</h3>
      <div class="mt-20 bg-white text-black p-10 rounded-xl leading-loose">
        <div class="flex flex-col items-center">
          <img :src="require('@/assets/images/happy_news.svg')" width="100px" />
          <h4 class="text-2xl font-semibold underline mt-5">Action Plan Content & Automations</h4>
        </div>
         <p class="mt-5">This content includes client centric transactional email templates that are customizable to your market and the property types in which you specialize.</p>
         <p class="mt-5">
          <ul class="list-disc">
            <li>Residential buyer sale checklist and transactional templates</li>
            <li>Residential seller sale checklist and transactional templates</li>
            <li>Residential pre-listing checklist and transactional templates</li>
            <li>Residential active listing checklist and transactional templates</li>
          </ul>
         </p>
        <hr>
        <div class="flex flex-col items-center">
          <img :src="require('@/assets/images/cleanup.svg')" width="120px" />
          <h4 class="text-2xl font-semibold underline mt-5">New Stages, Definitions, and Lead Flows</h4>
        </div>
         <p class="mt-5">
          <ol class="list-decimal">
            <li>Add transactional stages and define your new stages in your FUB account to help simplify your processes.</li>
            <li class="mt-5">Set up lead flows for transactions and past clients.</li>
          </ol>
        </p>
        <hr>
        <div class="flex flex-col items-center">
          <img :src="require('@/assets/images/lists.svg')" width="120px" />
          <h4 class="text-2xl font-semibold underline mt-5">Smart Lists</h4>
        </div>
        <p class="mt-5">There are a total of six transactional smart lists. These smart lists update automatically based on the filters set up. This will help you spend less time finding clients under contract and tracking closing dates.</p>
        <hr>
        <div class="flex flex-col items-center">
          <img :src="require('@/assets/images/lists.svg')" width="120px" />
          <h4 class="text-2xl font-semibold underline mt-5">Deals Pipelines</h4>
        </div>
        <p class="mt-5">Deals pipelines have been created to help keep track of sellers from signed listing agreement to close and Buyers through their contract process. Additionally, incoming and outgoing referral pipelines are setup as deals pipelines.</p>
        <hr>
        <div class="flex flex-col items-center">
          <img :src="require('@/assets/images/training.svg')" width="120px" />
          <h4 class="text-2xl font-semibold underline mt-5">Training</h4>
        </div>
         <p class="mt-5 font-semibold">After the Transaction Coordinator Build is complete, we provide the following training:</p>
         <ul class="list-disc mt-5">
          <li>one-on-one personalized implementation call with a customer success manager</li>
          <li>Access to our video training library for in-depth training.</li>
          <li>A cheat sheet for quick reference on the revamp components. Our cheat sheet also lays out a clear plan for what to do daily in your FUB account.</li>
          <li>One hour of one-on-one training with a customer success manager (each call is 30 minutes). Additional one-on-one training calls will cost extra.</li>
          <li>Access to bi-weekly calls with Chelly and other clients. These calls are interactive and you receive lifetime access.</li>
         </ul>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      showMoreActionPlans: false,
      showMoreDealsAutomations: false
    }
  }
}
</script>
<style scoped>
hr {
  @apply border-gray-300 my-5;
}
</style>
