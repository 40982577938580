<template>
  <LandingPageLayout @handleCTA="openSignUp" :navItems="navItems">
    <Hero ref="hero" :setupType="setupType" :calendarUrl="calendarUrl" />
    <Benefits :crm="crm" />
    <PainPoints :crm="crm" />
    <Testimonials v-if="crm === 'Follow Up Boss'" />
    <WhatsIncluded :crm="crm" />
    <FAQ :faqList="faqList" />
    <Pricing @openSignUp="openSignUp" :basePrice="basePrice" :addOns="addOns" />
    <OtherServices class="md:hidden" :crm="crm" />
    <Schedule @openSignUp="openSignUp" :calendarUrl="calendarUrl" />
  </LandingPageLayout>
</template>
<script>
import LandingPageLayout from '../../layouts/LandingPageLayout'
import Hero from '../../common/landing/Hero.vue'
import Benefits from './Benefits.vue'
import PainPoints from './PainPoints.vue'
import Testimonials from './Testimonials.vue'
import WhatsIncluded from './WhatsIncluded.vue'
import FAQ from '../../common/landing/FAQ.vue'
import Pricing from '../../common/landing/Pricing'
import Schedule from '../../common/landing/Schedule'
import OtherServices from './OtherServices.vue'
import { mapGetters } from 'vuex'
import { SETUP_TYPE, CALENDLY_CALENDARS, CALENDLY_UTM } from '../../../constants.js'
import { getPricing } from '@/pricing'
import { buildCalendarUrl } from '../../../utils'

export default {
  components: {
    LandingPageLayout,
    Hero,
    Benefits,
    PainPoints,
    Testimonials,
    WhatsIncluded,
    FAQ,
    Pricing,
    Schedule,
    OtherServices,
  },
  props: {
    setupType: {
      type: String,
      required: false,
      default: SETUP_TYPE.FUB_AGENT_REVAMP
    }
  },
  computed: {
    ...mapGetters(['agentRevampFaq', 'sierraFaq', 'affiliateName']),
    navItems() {
      let items = []

      if (this.setupType === SETUP_TYPE.FUB_AGENT_REVAMP) {
        items = items.concat([
          {
            text: 'Testimonials',
            scrollId: 'testimonials',
            showOnXLOnly: true
          }
        ])
      }

      items = items.concat([
        {
          text: "What's Included",
          scrollId: 'service'
        },
        {
          text: "Pricing",
          scrollId: 'pricing'
        },
        {
          text: 'Audit',
          to: '/audit'
        }
      ])

      if (this.setupType === SETUP_TYPE.FUB_AGENT_REVAMP) {
        items = items.concat([
          {
            text: 'Guides',
            subNavItems: [
              {
                text: 'Solo Agent Guide',
                to: '/solo'
              },
              {
                text: 'Team Lead Guide',
                to: '/team-lead'
              }
            ]
          }
        ])
      }

      return items
    },
    pricing() {
      return getPricing(this.setupType)
    },
    basePrice() {
      return this.pricing.BASE.toLocaleString('en')
    },
    faqList() {
      switch (this.setupType) {
        case SETUP_TYPE.SIERRA: return this.sierraFaq
        default: return this.agentRevampFaq
      }
    },
    crm() {
      return this.setupType === SETUP_TYPE.SIERRA ? 'Sierra' : 'Follow Up Boss'
    },
    addOns() {
      let addOns = []

      if (this.pricing.REVAMP_EXTENDED) {
        addOns.push({
          title: 'Revamp Extended',
          subtext: '* Additional action plans - Luxury Buyer and Seller, New Construction, Probate, Investor Buyer, Land Buyer, and more. See full list in "What\'s Included" section.',
          price: this.pricing.REVAMP_EXTENDED.toLocaleString('en')
        })
      }

      addOns.push({
        title: 'Platform Pairing',
        subtext: this.crm === 'Follow Up Boss' ? '* Additional action plans to support the following - Ylopo, RealScout, Fello, Conversion Monster' : '* Additional action plans to support the following - Ylopo, Fello, Conversion Monster',
        price: '100 per platform' 
      })

      if (this.pricing.PONDS_ADD_ON) {
        addOns.push({
          title: 'Ponds',
          subtext: `9 best practice ponds w/ corresponding action plans for managing new leads, re-engagements, re-inquiries, resurrections, vendors, off markets, and referral agents ${this.setupType === SETUP_TYPE.FUB_AGENT_REVAMP ? '(only available to FUB accounts that have more than one user)' : ''}`,
          price: this.pricing.PONDS_ADD_ON.toLocaleString('en')
        })
      }

      if (this.pricing.SPANISH_ACTION_PLANS) {
        addOns.push({
          title: 'Spanish Action Plans',
          price: this.pricing.SPANISH_ACTION_PLANS.toLocaleString('en'),
        })
      }

      if (this.pricing.LENDER_ADD_ON) {
        addOns.push({
          title: 'Lender Action Plans',
          price: this.pricing.LENDER_ADD_ON.toLocaleString('en'),
        })
      }

      if (this.pricing.SMS_DRIPS) {
        addOns.push({
          title: 'SMS Drips',
          subtext: '* Must have Leadngage, Texting Betty, or StreetText',
          price: this.pricing.SMS_DRIPS.toLocaleString('en'),
        })
      }

      if (this.pricing.PRIVATE_TEAM_TRAINING_CALL) {
        addOns.push({
          title: 'Private Team Training Call (1 hour)',
          price: this.pricing.PRIVATE_TEAM_TRAINING_CALL.toLocaleString('en'),
          subtext: 'One additional training call that your entire team may attend. * All other training calls are limited to two ambassadors from your team.'
        })
      }

      return addOns
    },
    calendarUrl() {
      const calendar = this.setupType === SETUP_TYPE.SIERRA ? CALENDLY_CALENDARS.SIERRA_DISCOVERY : CALENDLY_CALENDARS.DISCOVERY

      let utm

      if (this.affiliateName) {
        if (this.setupType === SETUP_TYPE.SIERRA) {
          utm = CALENDLY_UTM.SIERRA_AFFILIATE_DISCOVERY(this.affiliateName)
        } else {
          utm = CALENDLY_UTM.AFFILIATE_DISCOVERY(this.affiliateName)
        }
      } else if (this.setupType === SETUP_TYPE.KBC) {
        utm = CALENDLY_UTM.KBC
      } else if (this.setupType === SETUP_TYPE.SIERRA) {
        utm = CALENDLY_UTM.SIERRA_DISCOVERY
      } else {
        utm = CALENDLY_UTM.DISCOVERY
      }

      return buildCalendarUrl({ calendar, utm })
    },
  },
  methods: {
    openSignUp() {
      this.$refs.hero.openSignUp()
    }
  },
}
</script>